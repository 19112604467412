import React from 'react';

const CardStone: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H21C21.2652 20 21.5196 19.8946 21.7071 19.7071C21.8946 19.5196 22 19.2652 22 19V5C22 4.73478 21.8946 4.48043 21.7071 4.29289C21.5196 4.10536 21.2652 4 21 4H3ZM0.87868 2.87868C1.44129 2.31607 2.20435 2 3 2H21C21.7957 2 22.5587 2.31607 23.1213 2.87868C23.6839 3.44129 24 4.20435 24 5V19C24 19.7957 23.6839 20.5587 23.1213 21.1213C22.5587 21.6839 21.7957 22 21 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.316071 20.5587 0 19.7957 0 19V5C0 4.20435 0.316071 3.44129 0.87868 2.87868Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M4 16H13V18H4V16Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15 16H20V18H15V16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4053 5.5943L18.1855 6.21979C18.7518 6.67375 19.2088 7.24913 19.5229 7.90344C19.837 8.55774 20 9.27425 20 10C20 10.7258 19.837 11.4423 19.5229 12.0966C19.2088 12.7509 18.7518 13.3263 18.1855 13.7803L17.4053 14.4057L16.1543 12.8453L16.9345 12.2198C17.2671 11.9532 17.5354 11.6153 17.7199 11.2311C17.9043 10.8469 18 10.4262 18 10C18 9.57384 17.9043 9.15311 17.7199 8.7689C17.5354 8.38469 17.2671 8.04682 16.9345 7.78026L16.1543 7.15477L17.4053 5.5943Z"
      />
      <path d="M14 11.5C14.8284 11.5 15.5 10.8284 15.5 10C15.5 9.17157 14.8284 8.5 14 8.5C13.1716 8.5 12.5 9.17157 12.5 10C12.5 10.8284 13.1716 11.5 14 11.5Z" />
    </svg>
  );
};

export default CardStone;
